
.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9fa8b9;
}
.account-settings .about {
    margin: 2rem 0 0 0;
    text-align: center;
}
.account-settings .about h5 {
    margin: 0 0 15px 0;
    color: #007ae1;
}
.account-settings .about p {
    font-size: 0.825rem;
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c;
}

.upload{
    width: 100px;
    position: relative;
    margin: auto;
  }
  
  .upload img{
    border-radius: 50%;
    border: 6px solid #eaeaea;
  }
  
  .upload .round{
    position: absolute;
    bottom: 0;
    right: 0;
    background: #00B4FF;
    width: 32px;
    height: 32px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .upload .round input[type = "file"]{
    position: absolute;
    transform: scale(2);
    opacity: 0;
  }
  
  input[type=file]::-webkit-file-upload-button{
      cursor: pointer;
  }

  .form-ins1 {
    display: block;
    width: 100%;

    padding: 0.375rem 0.375rem;
    font-size: 0.775rem !important;
    font-weight: 300;
    line-height: 1.1;
    color: #0f1011; 

    background-color: #f8fcff;

 
    background-clip: padding-box;
    border: 1px solid #e1edf7 ; 

    appearance: none;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border-color: #9ccbf1   ;
    
    }
}

.lb-md {
    font-size: 12px;
    /* color: #1a6deb;
    color:  #c242f5 ; */
    color:  #9c48eb ;
    font-weight: 50;
  }
