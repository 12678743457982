/* ===================== to do page start ======================================= */

#task-container {
  ul {
    overflow: hidden;
    padding: 0;
  }

  li {
    float: left;
    width: 49%;
    overflow: auto;
    height: auto;
    min-height: 10px;
    background: #fff;
    display: inline-block;
    padding: 20px;
    border: 1px solid #ccc;
    color: #666;
    border-top: 5px solid $primary-color;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 2%;
    transition: all 0.3s;
    position: relative;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  li.complete {
    opacity: 1;
    border-top: 9px solid $danger-color;
    transition: all ease-in 0.3s;

    &:before {
      background: url('../../../images/complete.png') no-repeat;
      position: absolute;
      top: 5px;
      right: 5px;
      content: '';
      width: 55px;
      height: 55px;
      background-size: 100%;
    }

    p {
      text-decoration: line-through;
    }
  }
  @media screen and (max-width: 580px) {
    li {
      width: 100%;
    }
  }
}

.new-task {
  .to-do-list {
    .cr {
      float: left;
      top: 0;
      margin-right: 16px;
    }
  }

  label input[type='checkbox'] {
    display: none;
  }
}

.checkbox-fade {
  .cr {
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    margin-right: 0.5em;
    position: relative;
    width: 24px;
    margin-right: 16px;
    top: 0;
  }
}

.task-panel {
  .to-do-label {
    margin-bottom: 15px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding: 0;
    }
  }
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}

.to-do-list {
  i {
    color: #ccc;
    font-size: 17px;
    opacity: 0;
  }

  &:hover {
    i {
      opacity: 1;
      transition: opacity ease-in 0.3s;
    }
  }
}

.to-do-list {
  h6 {
    display: inline-block;
  }

  .done-task {
    opacity: 0.7;

    > div {
      filter: grayscale(0.8);
    }

    h6,
    p,
    span {
      text-decoration: line-through;
    }
  }

  .checkbox-fade {
    .check-task {
      display: block;
    }

    .to-content {
      display: inline-block;
    }

    .cr {
      border-radius: 6px;
      border: 1px solid #e3e3e3;
      cursor: pointer;
      display: inline-block;
      float: left;
      height: 24px;
      margin-right: 0.5em;
      position: relative;
      width: 24px;
      margin: 0;
      top: 5px;

      .cr-icon {
        color: #fff;
        font-size: 0.8em;
        left: 0;
        line-height: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 50%;
      }
    }

    label input[type='checkbox'] {
      display: none;
    }

    label input[type='checkbox'] + .cr > .cr-icon {
      transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      transition: all 0.3s ease-in;
    }

    label input[type='checkbox']:checked + .cr > .cr-icon {
      transform: scale(1) rotateZ(0deg);
      opacity: 1;
    }

    label input[type='checkbox']:checked + .cr {
      background: $primary-color;
      border: 0;
    }
  }
}

new-task {
  .to-do-list {
    .cr {
      float: left;
      top: 0;
      margin-right: 16px;
    }
  }

  label input[type='checkbox'] {
    display: none;
  }
}
/* ===================== to do page end =========================================*/
/* ===================== Gallery-masonry page start ============================= */

.gallery-masonry {
  .card-columns {
    column-count: 4;
  }
}
@media screen and (max-width: 1400px) {
  .gallery-masonry {
    .card-columns {
      column-count: 3;
    }
  }
}
@media screen and (max-width: 992px) {
  .gallery-masonry {
    .card-columns {
      column-count: 2;
    }
  }
}
@media screen and (max-width: 575px) {
  .gallery-masonry {
    .card-columns {
      column-count: 1;
    }
  }
}

.job-meta-data {
  i {
    margin-right: 5px;
    color: $primary-color;
  }
}
/* ===================== Gallery-masonry page end ============================= */
/* ===================== Task-list page start ============================= */

.task-data {
  img {
    width: 40px;
    box-shadow: none;
  }

  i {
    color: $theme-heading-color;
  }

  .dropdown-toggle {
    &:after {
      color: $theme-heading-color;
    }
  }
}

.task-board-left {
  .task-right-header-revision,
  .task-right-header-status,
  .task-right-header-users {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
  }

  .taskboard-right-progress,
  .taskboard-right-revision,
  .taskboard-right-users {
    padding-top: 10px;
  }

  .taskboard-right-progress {
    .progress {
      height: 9px;
      margin-bottom: 25px;
    }
  }

  .user-box {
    .media-object {
      height: 50px;
      width: 50px;
      cursor: pointer;
    }

    .media-left {
      position: relative;
    }

    .btn.btn-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .live-status {
    height: 8px;
    width: 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    top: 5px;
  }
}

.filter-bar {
  .navbar {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    padding: 0.5rem 1rem;
    @media screen and (max-width: 557px) {
      .f-text {
        display: block;
        width: 100%;
      }

      .f-view {
        padding-left: 24px;

        span {
          display: block;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }

  .label {
    border-radius: 4px;
    font-size: 13px;
    padding: 3px 7px;
  }

  .task-detail {
    margin-bottom: 5px;
  }

  .card-task {
    .task-list-table {
      display: inline-block;

      img {
        width: 40px;
        box-shadow: none;
      }

      i {
        color: $theme-heading-color;
      }
    }

    .task-board {
      float: right;
      margin-top: 5px;

      .dropdown {
        display: inline-block;
      }

      .btn {
        padding: 4px 10px;
        font-size: 10px;
        margin: 0;
      }
    }
  }
}

.assign-user,
.task-comment {
  img {
    width: 45px;
    box-shadow: none;
  }
}
/* ===================== Task-list page end ===================================== */
/* ===================== Invoice page start ===================================== */

.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.invoice-table.table {
  td {
    border: 0;
    padding: 4px 0;
  }
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;

  h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .invoice-order.table {
    padding-left: 0;

    th {
      border: 0;
      padding: 4px 0;

      &:first-child {
        padding-left: 0;
        width: 80px;
      }
    }
  }
}

.invoice-total.table {
  background: #f3f3f3;
  padding: 30px 0;

  th {
    border: 0;
    padding: 4px 0;
    text-align: right;
  }

  td {
    text-align: right;
  }

  tbody {
    padding-right: 20px;
    float: right;
  }
}

.invoice-summary {
  .label {
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 12px;
  }
}

.invoice-list {
  .btn {
    padding: 5px 10px;
    font-size: 12px;
  }

  .task-list-table {
    display: inline-block;
  }
}
/* ===================== Invoice page end ======================================= */
/* ==============================================================
                     email-page  Start
====================================================== */
.email-card {
  .tab-content {
    box-shadow: none;

    .btn {
      border: none;

      i {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    .table {
      tr {
        transition: all 0.12s ease-in-out;

        td,
        th {
          padding: 0.65rem 0.75rem;

          .checkbox {
            padding: 12px 0;
          }

          label {
            margin-bottom: 0;
          }
        }
        &.unread {
          font-weight: 600;
        }
        &.read {
          background: transparentize($theme-background, 0.3);
        }

        &:hover {
          background: $theme-background;
          box-shadow: 0 1px 3px 0 #e2e5e8;
        }
      }
    }

    .nav-pills {
      margin-left: 0;
      border-top: 1px solid $theme-border;
      padding: 15px 0 0;

      > li {
        .nav-link {
          border-radius: 0;
          border: none;
          padding: 5px 12px 22px;
          position: relative;

          &::after {
            content: '';
            width: calc(100% - 24px);
            height: 3px;
            position: absolute;
            left: 12px;
            bottom: 0;
            border-radius: 3px 3px 0 0;
          }

          &.active {
            background: transparent;
            color: $danger-color;

            &:after {
              background: $danger-color;
            }
          }
        }
      }
    }
  }

  .nav-pills {
    padding: 0;
    margin-left: -25px;

    > li {
      .nav-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $theme-font-size;
        padding: 8px 25px;
        border-radius: 0 20px 20px 0;
        text-transform: capitalize;
        border: 1px solid transparent;
        border-left: none;
        color: $theme-font-color;

        i {
          padding: 0;
          margin-right: 10px;
          font-size: 16px;
          display: inline-block;
          position: relative;
          top: 2px;
        }
      }

      .nav-link.active,
      .show > .nav-link {
        font-weight: 600;
        background: transparentize($danger-color, 0.9);
        color: $danger-color;
        border-color: transparentize($danger-color, 0.9);
      }
    }
  }

  .email-more-link {
    font-size: 16px;

    i {
      line-height: 1.1;
    }
  }

  .mail-body-content {
    a {
      color: $theme-font-color;
    }

    .mail-attach {
      display: inline-block;
      border: 1px solid $theme-border;
      border-radius: 50px;
      padding: 5px 15px;
      margin-top: 10px;
    }
  }

  .email-btn {
    .btn {
      border: none;
      &.dropdown-toggle::after {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .input-group {
      margin-top: 20px;
    }
  }
}

.email-content {
  border-bottom: 1px solid $theme-border;
  padding-bottom: 15px;
}

.email-read {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 575px) {
  .email-read {
    display: inline-block;

    .photo-table {
      margin-bottom: 10px;
    }
  }

  .email-contant {
    margin: 0 !important;
    padding: 0 !important;
  }
}
/* ==================  email-page  End  =================== */
/* ==============================================================
                     image-cropper  Start
====================================================== */

.docs-buttons {
  .btn {
    margin-bottom: 10px !important;
  }
}

.docs-data {
  .input-group {
    background: transparent;
    margin-bottom: 10px;
  }

  > .input-group > label {
    min-width: 80px;
  }

  .input-group > span {
    min-width: 50px;
  }
}

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px;

  > img {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    min-height: 516px;
  }
}

.docs-preview {
  margin-right: -15px;

  .img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    > img {
      max-width: 100%;
    }
  }

  .preview-lg {
    width: 100% !important;
  }

  .preview-md {
    width: 139px;
    height: 78px;
  }

  .preview-sm {
    width: 69px;
    height: 39px;
  }

  .preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0;
  }
}

.docs-buttons > .btn,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
  margin-right: 5px;
}

.docs-toggles > .btn,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
  margin-bottom: 10px;
}

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px;

  > .icon {
    margin: 0 -3px;
    vertical-align: top;
  }
}

.tooltip-inner {
  white-space: normal;
}

.btn-upload {
  .tooltip-inner {
    white-space: nowrap;
  }
}
@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important;

    > .btn {
      padding-left: 5px;
      padding-right: 5px;
    }

    .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.docs-options {
  .dropdown-menu {
    width: 100%;

    > li {
      padding: 3px 20px;
      font-size: 14px;

      &:hover {
        background-color: #f7f7f7;
      }

      > label {
        display: block;
      }
    }
  }
}

.docs-cropped {
  .modal-body {
    text-align: center;

    > canvas,
    > img {
      max-width: 100%;
    }
  }
}

.card-block {
  .docs-options {
    .dropdown-menu {
      top: inherit;
    }
  }
}
/* ==================  image-cropper end  ======================================= */
