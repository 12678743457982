/**  =====================
      Icon layouts css start
==========================  **/

.i-main {
  .i-block {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 5px;
    border: 1px solid #f1f1f1;
    position: relative;
    cursor: pointer;

    i {
      font-size: 30px;
    }

    label {
      margin-bottom: 0;
      display: none;
    }

    span.ic-badge {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .flag-icon-background {
      width: 40px;
      height: 40px;
    }
  }
}

/**====== Icon layouts css end ======**/
