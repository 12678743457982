/**  =====================
      Badges css start
==========================  **/

.badge {
  font-size: 80%;
  padding: 0.35em 0.6em;
  font-weight: 600;
}
@each $color, $value in $theme-colors {
  .badge-light-#{$color} {
    background: transparentize($value, 0.8);
    border-color: color-level($value, $alert-border-level);
    color: $value;
  }

  a.badge-light-#{$color} {
    &.active,
    &:active,
    &:focus,
    &:hover {
      background: $value;
      border-color: $value;
      color: color-level($value, $alert-bg-level);
    }
  }
}
/**====== Badges css end ======**/
